import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/incidencias',
        name: 'incidencias',
        component: () => import('../components/Incidencias/IncidenciasIndex.vue')
    },
    {
        path: '/incidencia/:id',
        name: 'incidencia',
        component: () => import('../components/Incidencias/IncidenciaDetail.vue')
    },
    {
        path: '/tareas',
        name: 'tareas',
        component: () => import('../components/Tareas/TareasIndex.vue')
    },
    {
        path: '/tarea/:id',
        name: 'tarea',
        component: () => import('../components/Tareas/TareaDetail.vue')
    },
    {
        path: '/registros',
        name: 'registros',
        component: () => import('../components/Registros/RegistrosIndex.vue')
    },
    {
        path: '/registro/:id',
        name: 'registro',
        component: () => import('../components/Registros/RegistroDetalle.vue')
    },
    {
        path: '/contadores',
        name: 'contadores',
        component: () => import('../components/Contadores/ContadoresIndex.vue')
    },
    {
        path: '/contador/:id',
        name: 'contador',
        component: () => import('../components/Contadores/ContadorDetalle.vue')
    },
    {
        path: '/tanques',
        name: 'tanques',
        component: () => import('../components/Tanques/TanquesIndex.vue')
    },
    {
        path: '/tanque/:id',
        name: 'tanque',
        component: () => import('../components/Tanques/TanqueDetalle.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
