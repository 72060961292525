<template>

    <nav>
        <router-link to="/" @click="hideNav">
            <i class="fa-solid fa-house"></i> Home
        </router-link>
        <router-link to="/incidencias" @click="hideNav">
            <i class="fa-solid fa-wrench"></i> Incidencias
        </router-link>
        <router-link to="/tareas" @click="hideNav">
            <i class="fa-solid fa-list-check"></i> Tareas
        </router-link>
        <router-link to="/registros" @click="hideNav">
            <i class="fa-solid fa-file-pen"></i> Registros
        </router-link>
        <router-link to="/contadores" @click="hideNav">
            <i class="fa-solid fa-gauge"></i> Contadores
        </router-link>
        <router-link to="/tanques" @click="hideNav">
            <i class="fa-solid fa-gauge"></i> Tanques
        </router-link>
    </nav>

</template>

<script>

    import {hideNav} from "@/functions";

    export default {
        props: [],

        name: 'NavMenu',

        computed: {},

        watch: {},

        methods: {
            hideNav() {
                hideNav();
            }
        },

        mounted() {
        },

        created: function () {

        }
    }


</script>
