<template>

    <div class="row">
        <div class="col-lg-2 col-6 mb-3">
            <div class="padding-card card-background border-radius-15 position-relative" style="height: 125px">
                <div class="font-40 font-200">
                    {{incidencias}}
                </div>

                <div class="text-uppercase font-800 position-absolute" style="bottom: 15px">
                    Incidencias
                </div>
            </div>
        </div>

        <div class="col-lg-2 col-6 mb-3">
            <div class="padding-card card-background border-radius-15 position-relative" style="height: 125px">
                <div class="font-40 font-200">
                    {{tareas}}
                </div>

                <div class="text-uppercase font-800 position-absolute" style="bottom: 15px">
                    Tareas
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import axios from 'axios';
    import config from "@/config";

    export default {

        props: [],

        name: 'HomeView',


        data() {
            return {
                incidencias: 0,
                tareas: 0,
            }
        },

        watch: {},

        methods: {
            getResumen() {
                let url = config.API_URL + '/sstt/resumen';

                axios.post(url, {}, {
                    headers: {Authorization: `Bearer ${localStorage.token}`}
                }).then(resp => {
                    this.incidencias = resp.data.incidencias;
                    this.tareas = resp.data.tareas;
                });
            },
            animateValue(id, start, end, duration) {
                if (start === end) return;
                let range = end - start;
                let current = start;
                let increment = end > start? 1 : -1;
                let stepTime = Math.abs(Math.floor(duration / range));

                let timer = setInterval(() => {
                    current += increment;
                    id = current;
                    if (current == end) {
                        clearInterval(timer);
                    }
                }, stepTime);
            }

        },

        mounted() {
        },

        created: function () {
            this.getResumen();
        }

    }


</script>
