<template>

    <div>
        <div class="border-radius-5 clearfix pe-2 cursor-pointer"
             @click="$refs.modalUser.openModal()"
             style="background-color: rgba(255, 255, 255, 0.7); padding: 5px 7px; border-radius: 50px 15px 15px 50px">
            <div class="float-start">
                <div style="height: 39px; width: 39px; border-radius: 50%" class="grey lighten-2 me-2">

                </div>
            </div>

            <div class="float-start">
                <div class="font-700">{{auth.name}}</div>
                <div class="font-600" style="font-size: 10px">{{auth.email}}</div>
            </div>
        </div>

        <!-- Modal User -->
        <ModalComponent ref="modalUser">
            <div class="padding-card white border-radius-15 mb-2 pb-5">
                <div class="font-20 font-500 float-start">{{auth.name}}</div>
                <div class="float-end mt-1 font-300">
                    {{auth.email}}
                </div>
            </div>

            <div>
                <button @click="$emit('logout'); $refs.modalUser.closeModal()" class="btn btn-sm white font-800 w-100">
                    <i class="fa-solid fa-right-from-bracket me-1"></i> Salir
                </button>
            </div>
        </ModalComponent>

    </div>

</template>

<script>

    import ModalComponent from "@/components/ModalComponent";

    export default {
        components: {ModalComponent},
        props: ['auth'],

        name: 'UserCard',

        data() {
            return {}
        },

        methods: {},

        mounted() {


        }

    }
</script>