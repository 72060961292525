<template>
    <div>
        <div class="modal fade"
             :id="'modalComponent' + id"
             tabindex="-1"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body p-2">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Modal} from "bootstrap";

    export default {
        props: [],

        name: 'ModalComponent',

        data() {
            return {
                id: null,
                modalInstance: null
            };
        },
        methods: {
            sendAction(url) {
                this.$emit('change', url);
            },
            openModal(){
                this.modalInstance.show();
            },
            closeModal(){
                this.modalInstance.hide();
            }
        },

        mounted() {
            // Init modal instance
            let modal = document.getElementById('modalComponent' + this.id);
            this.modalInstance = new Modal(modal);
        },

        created() {
            this.id = Math.floor(Math.random() * Date.now());
        },

    };
</script>