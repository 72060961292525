import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './scss/app.scss'

Object.defineProperty(String.prototype, 'capitalize', {
    value: function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});

createApp(App).use(router).mount('#app');
