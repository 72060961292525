export default {
    API_URL: process.env.VUE_APP_API_URL,
    STATUS: {
        STANDBY: 0,
        DOING: 1,
        DONE: 2,
        ERRROR: 3,
    }
}

const DATE_UNITS = { // in seconds
    year: 31536000,
    month: 2629800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
}

const languageCode = 'es' // English
const rtf = new Intl.RelativeTimeFormat(
    languageCode,
    { numeric: 'auto' }
)

export const getRelativeTime = (timestamp) => {
    const from = new Date(timestamp).getTime()
    const now = new Date().getTime()

    const elapsed = (from - now) / 1000

    for (const unit in DATE_UNITS) {
        if (Math.abs(elapsed) > DATE_UNITS[unit]) {
            return rtf.format(
                Math.floor(elapsed / DATE_UNITS[unit]),
                unit
            )
        }
    }
    // if less than one second, then is now
    return rtf.format(0, 'second')
}